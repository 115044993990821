<template>
    <header v-show="showHeader" ref="$headerElm" class="layout-header">
        <ClientOnly>
            <HeaderNotifications
                @notifications="handleNotificationStatus"
                class="layout-header__notification"
            />
        </ClientOnly>

        <HeaderAdBar v-if="adBar" :ad-bar="adBar" class="layout-header__adbars" />

        <div
            class="layout-header__place"
            :class="$config.public.variant === 'bchollos' ? 'bg-site-secondary' : 'bg-site-primary'"
        >
            <div class="layout-header__container">
                <HeaderMenuNew class="layout-header__menu" :admin-links="menuLink" />

                <NuxtLink
                    no-prefetch
                    to="/"
                    rel="follow"
                    class="layout-header__logo"
                    :class="showUserPanel ? '' : '!hidden'"
                >
                    <picture>
                        <source
                            v-if="$config.public.variant === 'megadescuentos'"
                            media="(min-width: 1024px)"
                            :srcset="$assets.brand.headerLogo"
                        />
                        <img
                            :src="
                                $config.public.variant === 'megadescuentos'
                                    ? $assets.brand.headerLogoLetter
                                    : $assets.brand.headerLogo
                            "
                            :alt="$lang.brand"
                            :title="$lang.brand"
                        />
                    </picture>
                </NuxtLink>

                <div class="layout-header__search">
                    <HeaderSearch
                        :on-open-handler="toggleUserPanel"
                        :default-categories="searchSuggestions.categories"
                        :default-stores="searchSuggestions.stores"
                        :default-events="searchSuggestions.events"
                    />
                </div>
            </div>
        </div>
        <HeaderHeadband :headband="headband" class="layout-header__headband" />
    </header>
</template>

<script lang="ts" setup>
import { useRootStore } from '~/store/root'

const RootStore = useRootStore()

const { $assets } = useNuxtApp()

const mounted = ref(false)

const showUserPanel = ref(true)

const showHeader = ref(true)

const preventScrollListener = ref(false)

const listener = ref(() => null) as Ref<(e?: any) => any>

const adBar = RootStore.layoutData?.data.header.adbar || false

const headband = RootStore.headbandData?.page

const menuLink = RootStore.layoutData?.data.header.links

const searchSuggestions = RootStore.layoutData?.data.header.search || {
    categories: [],
    stores: [],
    events: [],
}

const $headerElm = ref()

useHead({
    link: [
        {
            as: 'image',
            type: 'image/png',
            rel: 'preload',
            href: $assets.brand.headerLogo,
        },
        {
            rel: 'preload',
            as: 'image',
            type: 'image/png',
            href: $assets.brand.headerLogoLetter,
        },
    ],
})

onMounted(async () => {
    setTimeout(() => {
        mounted.value = true
    }, 3000)

    await nextTick()

    let lastScrollY = window.scrollY

    listener.value = () => {
        if (!preventScrollListener.value) {
            if (!$headerElm.value) return

            showHeader.value =
                lastScrollY > scrollY || ($headerElm.value as HTMLElement).clientHeight * 1.5 > scrollY

            lastScrollY = window.scrollY
        }
    }

    document.addEventListener('scroll', listener.value)
})

onUnmounted(() => {
    document.removeEventListener('scroll', listener.value)
})

const handleNotificationStatus = (notification: boolean) => {
    if (notification) {
        preventScrollListener.value = true
        showHeader.value = true
    } else {
        preventScrollListener.value = false
    }
}

const toggleUserPanel = (show: boolean) => {
    showUserPanel.value = show
}
</script>

<style lang="postcss" scoped>
.layout-header {
    @apply fixed left-0 top-0 z-[60] w-[100vw] shadow-md transition-all duration-300;

    height: var(--total-header-height, 0rem);

    &__notification {
        @apply absolute w-full transition-[bottom] duration-300;
        bottom: calc(
            var(--layout-header-adbar-height, 0rem) + var(--header-menu-height, 0rem) +
                var(--headband-height, 0rem)
        );
    }

    &__adbars {
        @apply absolute z-10 w-full transition-[bottom] duration-300;
        bottom: calc(var(--header-menu-height, 0rem) + var(--headband-height, 0rem));
    }

    &__place {
        @apply absolute z-50 w-full;
        bottom: var(--headband-height, 0rem);
    }

    &__headband {
        @apply absolute bottom-0 z-0;
    }

    &__container {
        @apply container flex h-14 items-center px-2 lg:h-16 lg:max-w-5xl;
    }
    &__logo {
        @apply order-2 block md:ml-2.5 lg:order-1 lg:ml-0;
        picture {
            @apply block h-12 max-w-[208px] lg:h-16;
            img {
                @apply block h-full w-full object-contain;
            }
        }
    }
    &__menu {
        @apply order-1 flex-1 md:flex-initial lg:order-2 lg:flex-grow;
    }
    &__search {
        @apply order-3 flex flex-1 justify-end lg:flex-initial;
    }
}
</style>
