<template>
    <Teleport to="#teleports">
        <LazyMiscModalPosition
            :close-handler="() => RootStore.setDiscountModal(null)"
            mode="full-screen"
            v-if="RootStore.discountModal"
        >
            <LazyDWidgetModal
                v-if="RootStore.discountModal"
                :info="RootStore.discountModal"
                @close="() => RootStore.setDiscountModal(null)"
            />
        </LazyMiscModalPosition>

        <LazyMiscMobileDropdown />
    </Teleport>
</template>

<script lang="ts" setup>
import { useRootStore } from '~/store/root'

const RootStore = useRootStore()
</script>

<style></style>
